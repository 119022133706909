import { login } from '@/api/login'
import { getToken, setToken} from '@/utils/auth'
import { Message } from 'element-ui'

const user = {
  state: {
    token: getToken(),
    personMail:{}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
	  playMail:(state, data) => {
		  state.personMail=data
	  },//既做保存首页新建人员信息，也做相应子页面获取数据后需要改变的操作
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const confirm = userInfo.confirm
      const times = userInfo.times
      return new Promise((resolve, reject) => {
        login(username, password, confirm, times).then(res => {
          if(res.result == 200){
            setToken(res.detail.token)
            commit('SET_TOKEN', res.detail.token)
            localStorage.setItem('username', res.detail.userinfo.username);
            localStorage.setItem('userid', res.detail.userinfo.id);
            localStorage.setItem('phone', res.detail.userinfo.phone);
            localStorage.setItem('level', res.detail.level.levelid);
            localStorage.setItem('levelname', res.detail.level.levelname);
            resolve(res.detail)
          }else{
            let msg = res.description;
            Message({
              message: msg,
              type: 'error'
            })
            reject(msg)
          }
         
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default user
