import Cookies from 'js-cookie'

const state = {
	educationmaninfo:'',
}

const mutations = {
	EDUCATIONMAN_INFO: (state, educationmaninfo) => {
		state.educationmaninfo = educationmaninfo
	}
}

const actions = {
	
	educationmaninfo({
		commit
	}, data) {
		commit('EDUCATIONMAN_INFO', data)
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
