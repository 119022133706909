import Vue from 'vue'


import './assets/css/reset.css';
import VueAMap from 'vue-amap';
Vue.use(VueAMap);

//默认高德 sdk 版本为 1.4.4
VueAMap.initAMapApiLoader({
    key: '588155c17c22841e5a6be8c1b367d63c',
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView',
        'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.MouseTool', "AMap.Geocoder", "AMap.Text", "AMap.Polygon",
        "AMap.InfoWindow", "AMap.Heatmap"
    ],
    v: '1.4.4',
    uiVersion: '1.0.11'
});
// ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, { size: 'small'});

import { NoticeBar, Swipe, SwipeItem } from 'vant';

Vue.use(NoticeBar).use(Swipe).use(SwipeItem);


import router from './router/index.js';
import App from './App.vue'
import store from './store'

// 引入RouterTab
import RouterTab from 'vue-router-tab'
import 'vue-router-tab/dist/lib/vue-router-tab.css'
Vue.use(RouterTab)

// 引入animated
import animated from 'animate.css' 
Vue.use(animated)

//引入数字累加跳动组件
// import VueNumber from 'vue-number-animation'
// Vue.use(VueNumber)

import Fragment from "vue-fragment";
Vue.use(Fragment.Plugin);

import './assets/css/common.css';
import './assets/css/them-default.scss';
import './assets/iconfont/iconfont.css'	//引入iconfont字体
import './assets/imgs/monitor/iconfont/iconfont.css'	//引入iconfont字体


//引入echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

//引入全局数据
import global_ from './utils/config'
Vue.prototype.GLOBAL = global_;



//手机号及固定电话判断
Vue.prototype.check_tel = function(tel) {
	var type = (/^((0\d{2,3}-\d{7,8})|(1[3|4|5|6|7|8|9][0-9]{9}))$/).test(tel.replace(/\s*/g, ""))
	return type;
}



// axios
import axios from 'axios';
axios.defaults.baseURL = global_.httpUrl;
Vue.prototype.$axios = axios;

// // 添加请求拦截器
// axios.interceptors.request.use(function(config) {
// 	// 在发送请求之前做些什么
// 	config.headers['Content-Type'] = 'application/json'
// 	config.headers['keyid'] = window.localStorage.getItem('sysadminId')
// 	config.headers['x-access-token'] = window.localStorage.getItem('token')
// 	return config;
// }, function(error) {
// 	// 对请求错误做些什么
// 	console.log(error) // for debug
// 	return Promise.reject(error);
// });

// // 添加响应拦截器
// axios.interceptors.response.use(response => {
// 	return response;
// },error => {
// 	if (error.response) {
// 		ElementUI.Message.error('系统错误');
// 		// 返回接口返回的错误信息
// 		return Promise.reject(error.response.data);
// 	}
// });


Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
